import classes from "./ResetareParola.module.scss";

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { auth } from "../../firebase";
import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from "firebase/auth";
import { Button } from "@mui/material";
import Spinner from "../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../Components/UI/AlertMessage/AlertMessage";
import TextInput from "../../Components/UI/Form/TextInput";

const ResetareParola = () => {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [actionCode, setActionCode] = useState('');
    const [accountEmail, setAccountEmail] = useState('');

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    useEffect(() => {
        setActionCode(params.oobCode);

        actionCode && verifyPasswordResetCode(auth, actionCode).then((email) => {
            setAccountEmail(email);
            setError(false);
            setLoading(false);
        }).catch((error) => {
            setError(true);
            setLoading(false);
            setAlert({
                title: "Link invalid",
                description: "Linkul de resetare a expirat sau a fost deja folosit. Te rugăm solicită din nou resetarea parolei."
            });
          });
    }, [actionCode, params.oobCode]);

    const onPasswordChange = (password) => {
        formik.setFieldValue("password", password, !!formik.touched.password);
    };

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("Introduceți o parolă")
                .min(8, "Parolă prea scurtă - trebuie sa aibă minim 8 caractere")
                .matches(/[a-zA-Z]/, "Parola poate conține doar caractere latine")
                .matches(
                    /[@#$%^&+!=]/,
                    "Parola trebuie să conțina minim un caracter special ( @,#,$,%,^,&,+,!,= )"
                ),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: (values) => {
          setLoading(true);

          confirmPasswordReset(auth, actionCode, values.password).then(() => {
            signInWithEmailAndPassword(auth, accountEmail, values.password)
                .then(() => {
                    setError(false);
                    setSuccess(true);
                    setAlert({
                        title: "Succes",
                        description: "Parola pentru contul tău a fost schimbată și ai fost re-logat cu succes."
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
          }).catch(() => {
              setError(true);
              setAlert({
                  title: "Eroare",
                  description: "A apărut o eroare în timpul confirmării. Te rugăm solicită din nou resetarea parolei."
              });
          });
        },
    });
    
    return (
        <div className='page-wrapper'>
            <div className='page-content'>
                <div className={classes['resetare-parola-wrapper']}>
                    <div class={classes['resetare-parola']}>
                        <h1>Resetare parolă</h1>
                        {loading && !error && <Spinner text='Un moment...' />}
                        {error && (
                            <AlertMessage
                                severity="error"
                                title={alert.title}
                                description={alert.description}
                            />
                        )}
                        {success && (
                            <AlertMessage
                                severity="success"
                                title={alert.title}
                                description={alert.description}
                            />
                        )}
                        {!loading && !success && !error && (
                            <form onSubmit={formik.handleSubmit}>
                                <p>Ai solicitat resetarea parolei pentru contul asociat adresei de email <strong>{accountEmail}</strong>. Introdu mai jos noua parolă:</p>
                                <TextInput
                                    label="Parolă nouă"
                                    changeHandler={onPasswordChange}
                                    error={formik.errors.password}
                                    secret
                                />
                                <Button type="submit" variant="contained">
                                    Salvează noua parolă
                                </Button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetareParola;
