import classes from './Footer.module.scss';
import Logo from '../../Media/Images/Misc/happy-logo-yellow-white.svg';
import AnpcSol from '../../Media/Images/Misc/anpc-sol.webp';
import AnpcSal from '../../Media/Images/Misc/anpc-sal.webp';
import { Link } from "react-router-dom";
import FooterDropdown from './FooterDropdown/FooterDropdown';

const Footer = () => {
    const dropdownItemsArray = [
        {
            nume:'Nuntă',
            type:'nunta',
            items:['n1']
        },
        {
            nume:'Botez',
            type:'botez',
            items:['b1']
        },
        {
            nume:'Nuntă și botez',
            type:'nunta-botez',
            items:['nb1']
        },
        {
            nume:'Aniversare',
            type:'aniversare',
            items:['a1']
        },
        {
            nume:'Corporate',
            type:'corporate',
            items:['c1']
        }
    ]
    return (
        <footer id={classes.footer}>
            <div>
                <img
                    src={Logo}
                    alt="Happy Events logo"
                    style={{
                        height: "59px",
                        width: "175px",
                      }}
                />
            </div>
            <div>
                <h4>Utile</h4>
                <ul>
                    <li>
                        <Link
                            to="/intrebari-frecvente"
                            id="intrebari-frecvente"
                            target="_blank"
                        >
                            Întrebări frecvente
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/termeni-si-conditii"
                            id="termeni-si-conditii"
                            target="_blank"
                        >
                            Termeni și condiții
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/politica-de-confidentialitate"
                            id="politica-de-confidentialitate"
                            target="_blank"
                        >
                            Politica de confidențialitate
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/politica-de-cookie-uri"
                            id="politica-de-cookie-uri"
                            target="_blank"
                        >
                            Politica de cookie-uri
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/contact"
                            id="contact"
                            target="_blank"
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
            <div>
                <h4>Invitații</h4>
                <ul>
                    {dropdownItemsArray.map((dropdownItem) => {
                        return (
                            <li key={`invitatie-${dropdownItem.type}`}>
                                <FooterDropdown
                                    id={`invitatie-${dropdownItem.type}`}
                                    nume={dropdownItem.nume}
                                    type={dropdownItem.type}
                                    items={dropdownItem.items}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div>
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN" target="_blank" rel="noreferrer">
                    <img 
                        src={AnpcSol}
                        alt="Soluționarea online a litigiilor"
                        title="Soluționarea online a litigiilor"
                        style={{ width: "200px", margin: "5px" }}
                    />
                </a>
                <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noreferrer">
                    <img 
                        src={AnpcSal}
                        alt="Soluționarea alternativă a litigiilor"
                        title="Soluționarea alternativă a litigiilor"
                        style={{ width: "200px", margin: "5px" }}
                    />
                </a><br />
                &copy; Copyright Happy Events 2025
            </div>
        </footer>
    );
}

export default Footer;