import classes from "./Spinner.module.scss";
import Loader from "../../../Media/Images/Misc/spinner.svg";

const Spinner = (props) => {
  return (
    <div className={classes.spinner}>
      <div>
        <img
          className={classes.spin}
          src={Loader}
          alt="Se încarcă..."
          style={{
            height: props.size ? `${props.size}rem` : "4rem",
            width: props.size ? `${props.size}rem` : "4rem",
          }}
        />
        {props.text && <p>{props.text}</p>}
      </div>
    </div>
  );
};

export default Spinner;
