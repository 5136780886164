import { useEffect, useState } from "react";
import sanitizeHtml from 'sanitize-html';

// Function to handle AUTH errors
export const errorHandler = (err) => {
    if (err.code === "auth/user-not-found") {
        return {
            severity: "error",
            title: "Eroare",
            description: "Nu există un cont cu această adresă de email!",
            refresh: "Încercați cu o altă adresă de email",
        };
    } else if (err.code === "auth/too-many-requests") {
        return {
            severity: "error",
            title: "Eroare",
            description: "Ați solicitat resetarea parolei de prea multe ori!",
        };
    } else if (err.code === "auth/email-already-in-use") {
        return {
            severity: "error",
            title: "Eroare",
            description:
                "Există deja un cont creat cu această adresă de email!",
            refresh: "Încercați din nou",
        };
    } else if (err.code === "auth/user-disabled") {
        return {
            severity: "error",
            title: "Eroare",
            description:
                "Contul asociat cu adresa de email cu care încercați să vă logați a fost dezactivat!",
            refresh: "Încercați cu un alt cont",
        };
    } else if (err.code === "auth/user-not-found") {
        return {
            severity: "error",
            title: "Eroare",
            description:
                "Nu există un cont creat cu adresa de email cu care încercați să vă logați!",
            refresh: "Încercați cu un alt cont",
        };
    } else if (err.code === "auth/wrong-password") {
        return {
            severity: "error",
            title: "Eroare",
            description: "Parolă incorectă!",
            refresh: "Încercați din nou",
        };
    } else if (err.code === "auth/not-activated") {
        return {
            severity: "error",
            title: "Cont neactivat",
            description: "Acest cont nu a fost încă activat. Activează-l accesând link-ul de activare primit pe adresa de email introdusă.",
        };
    } else {
        return {
            severity: "error",
            title: "Eroare",
            description: "A apărut o eroare. Vă rugăm încercați mai târziu!",
        };
    }
};

// Custom hook that checks if user is on mobile device
export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return width < 992;
};

// Function that converts JS Date to Romanian Date
export const dateToRo = (date) => {
    const months = [
        "ianuarie",
        "februarie",
        "martie",
        "aprilie",
        "mai",
        "iunie",
        "iulie",
        "august",
        "septembrie",
        "octombrie",
        "noiembrie",
        "decembrie",
    ];
    const day = new Date(date).getDate(),
        month = new Date(date).getMonth(),
        year = new Date(date).getFullYear();

    return `${day} ${months[month]} ${year}`;
};

// Function to process menus
export const menus = (...args) => {
    const [labelCodes] = args;
    const count = {};
    labelCodes && labelCodes.forEach(element => {
        count[element] = (count[element] || 0) + 1;
    });
    return count;
}

// Function to sanitize text input
export const sanitize = (dirty) => {
    return {
        __html: sanitizeHtml(dirty, {
            allowedTags: [ 'br', 'b', 'i' ],
            allowedAttributes: {},
            allowedIframeHostnames: []
        })
    };
}

// // Function to set a cookie
// export const setCookie = (cookie_name, cookie_value, expire_hours, path = "/") => {
//     const date = new Date();
//     date.setTime(date.getTime() + (expire_hours*60*60*1000));
//     const expires = `expires=${date.toUTCString()}`;
//     document.cookie = `${cookie_name}=${JSON.stringify(cookie_value)};${expires};path=${path}`;
// }

// // Function to get a cookie
// export const getCookie = (cookie_name) => {
//     const cookie = document.cookie
//         .split("; ")
//         .find((row) => row.startsWith(`${cookie_name}=`));

//     return cookie ? cookie.replace(`${cookie_name}=`, '') : "";
// }

// // Function to delete a cookie

// export const deleteCookie = (cookie_name, path = "/") => {
//     document.cookie = `${cookie_name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
// }
