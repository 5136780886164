import classes from "../../../Slides1.module.scss";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import dayjs from "dayjs";
import { dateToRo } from "../../../../../Helpers/Helpers";
import { v4 as uuidv4 } from "uuid";
import EditInvitatie from "../../../Components/Edit/Edit";
import FormSlide1 from "./FormSlide1";
import Countdown from "../../../Components/Countdown/Countdown";
import Scroll from "../../../Components/Scroll/Scroll";
import Cookies from "js-cookie";
import { sanitize } from "../../../../../Helpers/Helpers";

const lastSaturday = (demoNunta) => {
    const t =
        new Date(demoNunta).getDate() + (6 - new Date(demoNunta).getDay()) - 7;
    const lastSaturday = new Date(demoNunta);
    lastSaturday.setDate(t);
    return lastSaturday;
};
const saturdayIn3Months = lastSaturday(
    new Date().setMonth(new Date().getMonth() + 3)
);
const saturdayIn2Months = lastSaturday(
    new Date().setMonth(new Date().getMonth() + 2)
);

const Slide1 = (props) => {
    const [initialRender, setInitialRender] = useState(true);
    const [slide1Data, setSlide1Data] = useState({
        iid: uuidv4(),
        image: {
            value: "nb1s1_1",
            url: "/Media/Images/Modele/NuntaBotez/nb1s1_1",
        },
        text: "Ne vor fi alături nașii Dragoș și Lavinia Popescu dar și părinții Ion și Ioana Pop și Tudor și Teodora Ionescu. Vă așteptăm cu drag!",
        numeBebe: "Fabian",
        numeMireasa: "Ana",
        numeMire: "Ion",
        numarMire: "0722333444",
        numarMireasa: "0711222333",
        dataEveniment: dayjs(saturdayIn3Months),
        dataConfirmare: dayjs(saturdayIn2Months),
    });

    const savedSlide1Data = props.invId
        ? Cookies.get(`${props.invId}_nb1s1`)
        : Cookies.get("nb1s1");

    useEffect(() => {
        if (initialRender && !!savedSlide1Data) {
            setSlide1Data(JSON.parse(savedSlide1Data));
            setInitialRender(false);
        }
    }, [initialRender, savedSlide1Data]);

    const dispatch = useDispatch();

    const openModalSlide1 = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează prima pagină",
                modalContent: (
                    <FormSlide1
                        uid={props.uid}
                        invId={props.invId}
                        invTitle={props.invTitle}
                        slideData={slide1Data}
                        setSlideData={setSlide1Data}
                        setEventDate={props.setEventDate}
                        slideUpdated={props.slideUpdated}
                        editMode={props.editMode}
                    />
                ),
            })
        );
    };

    return (
        <div id={classes.slide_1}>
            {!props.live && (
                <EditInvitatie
                    tooltipText='Editează prima pagină'
                    openModal={openModalSlide1}
                    slide={1}
                />
            )}
            <div
                className={classes.image}
                style={{
                    backgroundImage: `url(${slide1Data.image.url}.jpg)`,
                }}
            >
                <div className={classes['scroll-wrapper-small']}>
                    <Scroll scrollTo={classes.slide_1_2} />
                </div>
            </div>
            <div id={classes.slide_1_2} className={classes.text}>
                <div className={classes["text-content"]}>
                    <div>
                        <p>
                            În data de <strong>{dateToRo(slide1Data.dataEveniment)}</strong> vă invităm la botezul minunii noastre
                        </p>
                        <h4>{slide1Data.numeBebe}</h4>
                        <p>urmat de cununia noastră religioasă</p>
                        <h4>
                            {slide1Data.numeMireasa} şi {slide1Data.numeMire}
                        </h4>
                    </div>
                    <div dangerouslySetInnerHTML={sanitize(slide1Data.text)} />
                    <Countdown
                        date={new Date(
                            slide1Data.dataEveniment
                        ).toLocaleDateString("en-US")}
                        color='black'
                        type='nunta-botez'
                    />
                    <div className={classes.bottom}>
                        <p>
                            * Vă rugăm să confirmaţi prezenţa până la data de{" "}
                            <strong>
                                {dateToRo(slide1Data.dataConfirmare)}
                            </strong>
                            .
                            <br />
                            ** Pentru orice nelămuriri ne puteți contacta la
                            telefon{" "}
                            {slide1Data.numarMire && (
                                <>
                                    <a href={`tel:${slide1Data.numarMire}`}>
                                        {slide1Data.numarMire}
                                    </a>{" "}
                                    ({slide1Data.numeMire})
                                </>
                            )}
                            {(slide1Data.numarMire && slide1Data.numarMireasa) &&
                                <>
                                    {" "}sau{" "}
                                </>
                            }
                            {slide1Data.numarMireasa &&
                                <>
                                    <a href={`tel:${slide1Data.numarMireasa}`}>
                                        {slide1Data.numarMireasa}
                                    </a>{" "}
                                    ({slide1Data.numeMireasa})
                                </>
                            }
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slide1;
